<template>
  <!--begin::Advance Table Widget 9-->
  <div>

    <div class="card-body"  v-if="orders.length==0">
      <center><h2 class="font-poppins">No Orders for Today </h2></center>

    </div>
    <div class="card card-custom card-stretch gutter-b" v-else>
    <!--begin::Header-->
   
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark font-poppins">{{ title }}</span>
      </h3>
    </div>
    

    <!--end::Header-->
    <!--begin::Body-->
    <div  class="card-body pt-0 pb-3">
      <div class="">
        <!-- <v-row class="d-flex justify-end" style="    justify-content: end;">
    <v-col cols="12" sm="12" md="3">
      <v-select
        v-model="selectedStatus"
        :items="statusOptions"
        label="Select Status"
        outlined
      ></v-select>
    </v-col>

    <v-col cols="12" sm="12" md="3">
      <v-button
        @click="filterTodaysDeliverables"
        color="primary"
        text-color="white"
        outlined
        class="bg-primary"
      >
        Today's Deliverables
      </v-button>
    </v-col>
  </v-row> -->
        <v-row class="d-none d-sm-flex px-5">
          <v-col cols="12" sm="12" md="3">
            <span style="min-width: 50px">
              <span class="font-poppins font-weight-bold">Order #</span>
            </span>
          </v-col>
          <v-col cols="12" sm="12" md="3">
            <span style="min-width: 120px" class="font-poppins font-weight-bold">Order Details</span>
          </v-col>
          <v-col cols="12" sm="12" md="3">
            <th style="min-width: 120px" class="font-poppins font-weight-bold">Order Status</th>
          </v-col>
          <v-col cols="12" sm="12" md="3">
            <th style="min-width: 120px" class="font-poppins font-weight-bold">Action</th>

          </v-col>
        </v-row>
        <v-row
          class="mt-10 mx-1 p-2 mb-1"
          style="background-color:#F6FBFF;border-radius:20px;"
          v-for="(item, i) in orders"
          v-bind:key="i"
        >
          <v-col cols="12" sm="12" md="2">
            <span
              class="text-dark-75 font-weight-normal d-block font-poppins font-size-lg"
            >{{ item.customer.name }} # {{ item.order_number }}</span>
            <span class="text-muted font-weight-normal font-poppins d-block">
              <b-badge v-if="item.payment_method == 'card'" class="mr-1 font-poppins" variant="success">PAID/CARD</b-badge>
              <b-badge v-else class="mr-1 font-poppins" variant="danger">UNPAID</b-badge>
            </span>

            
          </v-col>
          <v-col cols="12" sm="12" md="3">
            <span
              v-if="item.type == colctn"
              class=" font-weight-normal font-poppins d-block font-size-lg"
            >
              Collection: {{ item.collection_date }}
              {{ item.collection_time }}
            </span>
            <span class="  font-weight-normal font-poppins">
              Ordered on: {{ item.created_at }}
              <br />
              Order Value: {{$store.state.auth.currency}}{{ parseFloat(item.total).toFixed(2) }}
            </span>
          </v-col>
          <v-col cols="12" sm="12" md="3">
            <b-badge v-if="item.status === cncld" class="mr-1" variant="danger">
              <span class="font-weight-normal">{{ item.status }}</span>
              &nbsp;
              <span>{{ item.status_date }}</span>
            </b-badge>
            <b-badge v-if="item.status === pend" class="mr-1" variant="primary">
              <span class="font-weight-normal">{{ item.status }}</span>
              &nbsp;
              <span>{{ item.status_date }}</span>
            </b-badge>
            <b-badge
              v-if="item.status === ack || item.status === preprd || item.status === onWay"
              class="mr-1"
              variant="warning"
            >
              <span class="font-weight-normal">{{ item.status }}</span>
              &nbsp;
              <span>{{ item.status_date }}</span>
            </b-badge>
            <b-badge
              v-if="item.status === colctd || item.status === delvrd"
              class="mr-1"
              variant="success"
            >
              <span class="font-weight-normal">{{ item.status }}</span>
              &nbsp;
              <span>{{ item.status_date }}</span>
            </b-badge>
          </v-col>

          <v-col cols="12" sm="12" md="4">
            <div
              v-if="
                      title === 'Orders Cancelled' ||
                        title === 'Orders History' ||
                        title === 'Orders Combined' ||
                        item.status === colctd
                    "
            >
              <td class="pr-0">
                <a
                  @click="detail(item)"
                  class="btn btn-light-info font-weight-bolder m-1 font-size-sm"
                >View Order</a>
                <a
                  target="_blank"
                  href="#"
                  @click="print(item)"
                  class="btn btn-light-primary font-weight-bolder m-1 font-size-sm"
                >Print</a>
                <a
                @click="deleteOrder(item)"
                  v-if="
                          item.status != delvrd && item.status != colctd && item.status != onWay && item.status != preprd
                        "
                  class="btn btn-light-danger font-weight-bolder  font-size-sm m-1"
                >Delete Order</a>
              </td>
            </div>
            <div v-else-if="item.status === cncld">
              <td class="pr-0">
                <a
                  @click="detail(item)"
                  class="btn   btn-light-info font-weight-bolder mr-1 font-size-sm"
                >View Order</a>
                <a
                  target="_blank"
                  @click="print(item)"
                  class="btn btn-light-primary font-weight-bolder mr-1 font-size-sm"
                >Print</a>
              </td>
            </div>
            <div v-else-if="item.status === ack">
              <td class="pr-0">
                <a
                  @click="detail(item)"
                  class="btn btn-light-info font-weight-bolder mr-1 font-size-sm"
                >View Order</a>
                <a
                  target="_blank"
                  class="btn btn-light-primary font-weight-bolder mr-1 font-size-sm"
                  @click="print(item)"
                >Print</a>
                <a
                  @click="ready(item)"
                  class="btn btn-light-warning font-weight-bolder mr-1 font-size-sm"
                >Order Prepared</a>
              </td>
            </div>
            <div v-else-if="item.status === preprd || item.status === onWay">
              <td class="pr-0">
                <a
                  @click="detail(item)"
                  class="btn btn-light-info font-weight-bolder mr-1 font-size-sm"
                >View Order</a>
                <a
                  target="_blank"
                  class="btn btn-light-primary font-weight-bolder mr-1 font-size-sm"
                  @click="print(item)"
                >Print</a>
                <a
                  v-if="item.type == colctn"
                  @click="collect(item)"
                  class="btn btn-light-success font-weight-bolder mr-1 font-size-sm"
                >Collected</a>
                <a
                  v-if="item.type == dlvry && item.status === preprd"
                  @click="way(item)"
                  class="btn btn-light-warning font-weight-bolder mr-1 font-size-sm"
                >Order On Way</a>
                <a
                  v-if="item.type == dlvry && item.status === onWay"
                  @click="deliver(item)"
                  class="btn btn-light-success font-weight-bolder mr-1 font-size-sm"
                >Delivered</a>
              </td>
            </div>
            <div v-else>
              <td class="pr-0">
                <a
                  @click="detail(item)"
                  class="btn btn-light-info font-weight-bolder mr-1 font-size-sm"
                >View Order</a>
                <a
                  target="_blank"
                  @click="print(item)"
                  class="btn btn-light-primary font-weight-bolder mr-1 font-size-sm"
                >Print</a>
                <a
                  v-if="item.status != delvrd && item.status != colctd"
                  @click="cancel(item)"
                  class="btn btn-light-danger font-weight-bolder mr-1 font-size-sm"
                >Cancel</a>
                <a
                  v-if="item.status != delvrd && item.status != colctd"
                  @click="acknowledge(item)"
                  class="btn btn-light-success font-weight-bolder m-1 font-size-sm"
                >Acknowledge</a>
              </td>
            </div>
            <div v-if="item.payment_method === 'cod' && item.status === 'Pending'">
              <a
                @click="GenerateLink(item)"
                v-if="false"
                class="btn btn-light-info font-weight-bolder mr-1 font-size-sm"
              >Generate Payment Link</a>
            </div>
          </v-col>
        </v-row>
        <!-- <div class="table-responsive">
          <table
            class="
              table
              table-head-custom
              table-vertical-center
              table-head-bg
              table-borderless
            "
          >
            <thead>
              <tr class="text-left">
                <th style="min-width: 50px">
                  <span class="text-dark-75">Orders #</span>
                </th>
                <th style="min-width: 120px">Order Details</th>
                <th style="min-width: 120px">Order Status</th>

                <th style="min-width: 100px">Action</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, i) in orders">

                <tr :key="i">
                  <td>
                    <span
                      class="
                        text-dark-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                      "
                    >
                      {{ item.customer.name }} # {{ item.order_number }}
                    </span>
                    <span class="text-muted font-weight-bold d-block">
                      <b-badge
                        v-if="item.payment_method == 'card'"
                        class="mr-1"
                        variant="success"
                      >
                        PAID/CARD
                      </b-badge>
                      <b-badge v-else class="mr-1" variant="danger">
                        UNPAID
                      </b-badge>
                    </span>
                  </td>
                  <td>
                    <span
                      v-if="item.type == colctn"
                      class="
                        text-dark-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                      "
                    >
                      Collection: {{ item.collection_date }}
                      {{ item.collection_time }}
                    </span>
                    <span class="text-muted text-dark-75 font-weight-bold">
                      Ordered on: {{ item.created_at }} <br />
                      Order Value: {{$store.state.auth.currency}}{{ parseFloat(item.total).toFixed(2) }}
                    </span>
                  </td>
                  <td>
                    <b-badge
                      v-if="item.status === cncld"
                      class="mr-1"
                      variant="danger"
                    >
                    <span class="order-status">{{ item.status }}</span>
                      <br />
                      <br />
                      <span>{{ item.status_date }}</span>
                    </b-badge>
                    <b-badge
                      v-if="item.status === pend"
                      class="mr-1"
                      variant="primary"
                    >
                    <span class="order-status"> {{ item.status }}</span>
                      <br />
                      <br />
                      <span>{{ item.status_date }}</span>
                    </b-badge>
                    <b-badge
                      v-if="item.status === ack || item.status === preprd || item.status === onWay"
                      class="mr-1"
                      variant="warning"
                    >
                    <span class="order-status">{{ item.status }}</span>
                      <br />
                      <br />
                      <span>{{ item.status_date }}</span>
                    </b-badge>
                    <b-badge
                      v-if="item.status === colctd || item.status === delvrd"
                      class="mr-1"
                      variant="success"
                    >
                    <span class="order-status">{{ item.status }}</span>
                      <br />
                      <br />
                      <span>{{ item.status_date }}</span>
                    </b-badge>
                  </td>
                  <div
                    v-if="
                      title === 'Orders Cancelled' ||
                        title === 'Orders History' ||
                        title === 'Orders Combined' ||
                        item.status === colctd
                    "
                  >
                    <td class="pr-0">
                      <a
                        @click="detail(item)"
                        class="
                          btn btn-light-info
                          font-weight-bolder
                          mr-1
                          font-size-sm
                        "
                        >View Order</a
                      >
                      <a
                        target="_blank"
                        href="#"
                        @click="print(item)"
                        class="
                          btn btn-light-primary
                          font-weight-bolder
                          mr-1
                          font-size-sm
                        "
                        >Print</a
                      >
                      <a
                        v-if="
                          item.status != delvrd && item.status != colctd && item.status != onWay && item.status != preprd
                        "
                        class="
                          btn btn-light-danger
                          font-weight-bolder
                          mr-1
                          font-size-sm
                        "
                        >Delete Order</a
                      >
                    </td>
                  </div>
                  <div v-else-if="item.status === cncld">
                    <td class="pr-0">
                      <a
                        @click="detail(item)"
                        class="
                          btn btn-light-info
                          font-weight-bolder
                          mr-1
                          font-size-sm
                        "
                        >View Order</a
                      >
                      <a
                        target="_blank"
                        @click="print(item)"
                        class="
                          btn btn-light-primary
                          font-weight-bolder
                          mr-1
                          font-size-sm
                        "
                        >Print</a
                      >
                    </td>
                  </div>
                  <div v-else-if="item.status === ack">
                    <td class="pr-0">
                      <a
                        @click="detail(item)"
                        class="
                          btn btn-light-info
                          font-weight-bolder
                          mr-1
                          font-size-sm
                        "
                        >View Order</a
                      >
                      <a
                        target="_blank"
                        class="
                          btn btn-light-primary
                          font-weight-bolder
                          mr-1
                          font-size-sm
                        "
                        @click="print(item)"
                        >Print</a
                      >
                      <a
                        @click="ready(item)"
                        class="
                          btn btn-light-warning
                          font-weight-bolder
                          mr-1
                          font-size-sm
                        "
                        >Order Prepared</a
                      >
                    </td>
                  </div>
                  <div v-else-if="item.status === preprd || item.status === onWay">
                    <td class="pr-0">
                      <a
                        @click="detail(item)"
                        class="
                          btn btn-light-info
                          font-weight-bolder
                          mr-1
                          font-size-sm
                        "
                        >View Order</a
                      >
                      <a
                        target="_blank"

                        class="
                          btn btn-light-primary
                          font-weight-bolder
                          mr-1
                          font-size-sm
                        "
                        @click="print(item)"
                        >Print</a
                      >
                      <a
                        v-if="item.type == colctn"
                        @click="collect(item)"
                        class="
                          btn btn-light-success
                          font-weight-bolder
                          mr-1
                          font-size-sm
                        "
                        >Collected</a
                      >
                      <a
                        v-if="item.type == dlvry && item.status === preprd"
                        @click="way(item)"
                        class="
                          btn btn-light-warning
                          font-weight-bolder
                          mr-1
                          font-size-sm
                        "
                        >Order On Way</a
                      >
                      <a
                        v-if="item.type == dlvry && item.status === onWay"
                        @click="deliver(item)"
                        class="
                          btn btn-light-success
                          font-weight-bolder
                          mr-1
                          font-size-sm
                        "
                        >Delivered</a
                      >
                    </td>
                  </div>
                  <div v-else>
                    <td class="pr-0">
                      <a
                        @click="detail(item)"
                        class="
                          btn btn-light-info
                          font-weight-bolder
                          mr-1
                          font-size-sm
                        "
                        >View Order</a
                      >
                      <a
                        target="_blank"
                        @click="print(item)"
                        class="
                          btn btn-light-primary
                          font-weight-bolder
                          mr-1
                          font-size-sm
                        "
                        >Print</a
                      >
                      <a
                        v-if="item.status != delvrd && item.status != colctd"
                        @click="cancel(item)"
                        class="
                          btn btn-light-danger
                          font-weight-bolder
                          mr-1
                          font-size-sm
                        "
                        >Cancel</a
                      >
                      <a
                        v-if="item.status != delvrd && item.status != colctd"
                        @click="acknowledge(item)"
                        class="
                          btn btn-light-success
                          font-weight-bolder
                          mr-1
                          font-size-sm
                        "
                        >Acknowledge</a
                      >
                    </td>
                  </div>
                  <div v-if="item.payment_method === 'cod' && item.status === 'Pending'">
                    <a
                        @click="GenerateLink(item)"
                        v-if="false"
                        class="
                          btn btn-light-info
                          font-weight-bolder
                          mr-1
                          font-size-sm
                        "
                        >Generate Payment Link</a
                      >
                  </div>  
                </tr>
              </template>
            </tbody>
          </table>
        </div>-->
        <!--end::Table-->
      </div>
    </div>
    


    <b-modal
      id="order-invoice-modal"
      ref="order-invoice-modal"
      size="md"
      title="Order Invoice"
      hide-footer
    >
      <Invoice :data="product" @onClickCancel="hideModal"></Invoice>
    </b-modal>

    <b-modal
      id="cancel-order-modal"
      ref="cancel-order-modal"
      size="md"
      title="Cancel Order"
      hide-footer
    >
      <CancelOrder @cancelReason="cancelOrder" @onClickCancel="hideModal"></CancelOrder>
    </b-modal>
    <b-modal
      id="generate-payment-modal"
      ref="generate-payment-modal"
      size="md"
      title="Generate Payment Link"
      hide-footer
    >
      <div>
        <div class="form-group">
          <label>TO</label>
          <b-form-input v-model="product.customer_phone"></b-form-input>
        </div>
        <div class="form-group">
          <label>Amount</label>
          <b-form-input id="textarea" v-model="product.final_amount"></b-form-input>
        </div>
        <div class="form-group">
          <label>Please Enter message</label>
          <b-form-textarea id="textarea" v-model="paymentmessage" rows="3" max-rows="6" value></b-form-textarea>
        </div>
        <button
          v-on:click="createLink"
          style="float:right"
          class="btn btn-danger mt-1"
          :disabled="paymentlinkbtn"
        >Send Link</button>
      </div>
    </b-modal>
    <!--end::Body-->
  </div>
  
  </div>

  <!--end::Advance Table Widget 9-->
</template>

<style>
.blink_me {
  animation: blinker 5s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
/*

	@keyframes blinkingText{
		0%		{ color: #10c018;}
		25%		{ color: #1056c0;}
		50%		{ color: #ef0a1a;}
		75%		{ color: #254878;}
		100%	{ color: #04a1d5;}
	} */
</style>
<script>
import Swal from "sweetalert2";

import {
  CHANGE_STATUS,
  GENERATE_LINK
} from "@/core/services/store/sales.module";
import Invoice from "@/view/pages/orders/components/Invoice";
import CancelOrder from "@/view/pages/orders/components/CancelOrder";
import GeneratePaymentLink from "@/view/pages/orders/components/GeneratePaymentLink";
import ApiService from "@/core/services/api.service";

import { mapGetters } from "vuex";

export default {
  name: "OrdersTableWidget",
  components: {
    Invoice,
    CancelOrder,
    GeneratePaymentLink
  },
  computed: {
    ...mapGetters([
      "pending",
      "cancelled",
      "acknowledged",
      "delivered",
      "collected",
      "delivery",
      "collection",
      "orderPrepared",
      "orderWay"
    ]),
    colctd() {
      return this.collected;
    },
    pend() {
      return this.pending;
    },
    ack() {
      return this.acknowledged;
    },
    delvrd() {
      return this.delivered;
    },
    cncld() {
      return this.cancelled;
    },
    dlvry() {
      return this.delivery;
    },
    colctn() {
      return this.collection;
    },
    preprd() {
      return this.orderPrepared;
    },
    onWay() {
      return this.orderWay;
    }
  },
  data() {
    return {
      statusOptions:[
        "Pending",
        "Completed"
      ],
      selectedStatus:"",
      link: "",
      paymentlinkbtn: false,
      product: [],
      blink_me: "blink_me",
      paymentmessage: "here is the link to pay for your order"
    };
  },
  props: ["orders", "title"],
  methods: {
    deleteOrder(order){
      console.log('order',order)
      Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(( result) => {
      if (result.isConfirmed) {
         ApiService.delete("business/sales/order/" + order.order_id).then(() => {
          Swal.fire("Deleted!", "Business has been deleted.", "success");
          window.location.reload()
        });
      }

    });
    },
    GenerateLink(item) {
      this.product = item;
      this.$refs["generate-payment-modal"].show();
    },
    createLink() {
      this.paymentlinkbtn = true;

      var form = {
        phone: this.product.customer_phone,
        message: this.paymentmessage,
        amount: this.product.final_amount,
        order_id: this.product.id
      };

      this.$store.dispatch(GENERATE_LINK, form).then(res => {
        this.$emit("update");
        this.makeToast("success", "Link sent !");
        this.$refs["generate-payment-modal"].hide();
        this.paymentlinkbtn = false;
      });
    },
    print(item) {
      console.log(item);
      window.open(
        process.env.VUE_APP_API_URL + "order-invoice?order_id=" + item.order_id,
        "_blank"
      );
    },
    hideModal() {
      this.$refs["order-invoice-modal"].hide();
      this.$refs["cancel-order-modal"].hide();
    },
    showModal(item) {
      this.product = item;
      this.$refs["order-invoice-modal"].show();
    },
    makeToast(variant, message) {
      this.$bvToast.toast(message, {
        title: "Message",
        variant: variant,
        solid: true
      });
    },
    detail(data) {
      this.$router.push({
        name: "detail",
        params: {
          items: data
        }
      });
    },
    cancel(item) {
      this.product = item;
      this.$refs["cancel-order-modal"].show();
    },
    cancelOrder(reason) {
      this.$refs["cancel-order-modal"].hide();
      var form = {
        order_id: this.product.order_id,
        status: this.cancelled,
        reason: reason
      };
      this.$store.dispatch(CHANGE_STATUS, form).then(res => {
        this.$emit("update");
        this.makeToast("success", "Status Updated Successfully !");
      });
    },
    acknowledge(item) {
      var form = {
        order_id: item.order_id,
        status: this.acknowledged
      };
      this.$store.dispatch(CHANGE_STATUS, form).then(res => {
        this.$emit("update");
        this.makeToast("success", "Status Updated Successfully !");
      });
    },
    deliver(item) {
      var form = {
        order_id: item.order_id,
        status: this.delivered
      };
      this.$store.dispatch(CHANGE_STATUS, form).then(res => {
        this.$emit("update");
        this.makeToast("success", "Status Updated Successfully !");
      });
    },
    collect(item) {
      var form = {
        order_id: item.order_id,
        status: this.collected
      };
      this.$store.dispatch(CHANGE_STATUS, form).then(res => {
        this.$emit("update");
        this.makeToast("success", "Status Updated Successfully !");
      });
    },
    ready(item) {
      var form = {
        order_id: item.order_id,
        status: this.orderPrepared
      };
      this.$store.dispatch(CHANGE_STATUS, form).then(res => {
        this.$emit("update");
        this.makeToast("success", "Status Updated Successfully !");
      });
    },
    way(item) {
      var form = {
        order_id: item.order_id,
        status: this.onWay
      };
      this.$store.dispatch(CHANGE_STATUS, form).then(res => {
        this.$emit("update");
        this.makeToast("success", "Status Updated Successfully !");
      });
    }
  },
  mounted() {
    this.link = process.env.VUE_APP_API_BASE_URL;
  }
};
</script>
<style >
</style>
 